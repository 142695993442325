import * as React from 'react'

const SvgComponent = props => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <g data-name='\u56FE\u5C42 1'>
        <path
          d='M22.78 1.08a137.9 137.9 0 0 1 34.45 0 25 25 0 0 1 21.69 21.7 137.9 137.9 0 0 1 0 34.45 25 25 0 0 1-21.7 21.69A137.63 137.63 0 0 1 40 80a138.23 138.23 0 0 1-17.22-1.08 25 25 0 0 1-21.7-21.69 137.9 137.9 0 0 1 0-34.45 25 25 0 0 1 21.7-21.7Z'
          style={{
            fill: '#e9ebff',
          }}
        />
        <path
          d='M21.67 50v10h36.66V50h-8.78a5 5 0 0 1-4.95-4.27l-1.41-9.61 1-.57a8.33 8.33 0 1 0-8.34 0l1 .57-1.45 9.61A5 5 0 0 1 30.45 50Z'
          style={{
            fill: '#6b6cf9',
          }}
        />
      </g>
    </g>
  </svg>
)

export default SvgComponent
