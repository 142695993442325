import * as React from 'react'

const SvgComponent = props => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 50' {...props}>
    <defs>
      <style>{'.cls-03{fill:#6b6cff}'}</style>
    </defs>
    <g id='\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          id='Rectangle-23'
          d='M58.5 1.5v47h-57v-47Z'
          style={{
            fill: 'none',
            stroke: '#363649',
            strokeMiterlimit: 10,
            strokeWidth: 3,
          }}
        />
        <g id='Group'>
          <path
            id='Combined-Shape'
            style={{
              fill: '#363649',
            }}
            d='m18.82 17.25-7.07 7.07-1.77-1.77 5.31-5.3-5.31-5.3 1.77-1.77 7.07 7.07z'
          />
        </g>
        <path id='Rectangle-109' className='cls-03' d='M23 16h10v3H23z' />
        <path id='Rectangle-108' className='cls-03' d='M8 28h44v13H8z' />
      </g>
    </g>
  </svg>
)

export default SvgComponent
