import * as React from 'react'

const SvgComponent = props => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80' {...props}>
    <defs>
      <style>{'.cls-2{fill:#6b6cf9}'}</style>
    </defs>
    <g id='\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          id='a'
          d='M22.78 1.08a137.9 137.9 0 0 1 34.45 0 25 25 0 0 1 21.69 21.7 137.9 137.9 0 0 1 0 34.45 25 25 0 0 1-21.7 21.69A137.63 137.63 0 0 1 40 80a138.23 138.23 0 0 1-17.22-1.08 25 25 0 0 1-21.7-21.69 137.9 137.9 0 0 1 0-34.45 25 25 0 0 1 21.7-21.7Z'
          style={{
            fill: '#e9ebff',
          }}
        />
        <path
          className='cls-2'
          d='M58 24.61c-7.25-7.52-14.77-.54-15-.27L31.12 36.43A6.72 6.72 0 0 0 36 38.58c5.11 0 10.21-5.64 10.21-5.64l.81-.81 9.66 10.21c1.88-1.88 4.84-5.38 4.84-9.67.22-2.96-1.13-5.67-3.52-8.06ZM30.58 43.14a1 1 0 0 0 .27-.8 1.4 1.4 0 0 0-.54-1.08 2 2 0 0 0-1.61-.54 2.06 2.06 0 0 0-1.07.54l-4.3 5.37a1.71 1.71 0 0 0 .27 2.42 1.29 1.29 0 0 0 1.88 0v-.27l4.3-5.37a1 1 0 0 0 .8-.27ZM34.34 46.9c0-.27.27-.8.27-1.07a1.4 1.4 0 0 0-.54-1.08 1.48 1.48 0 0 0-1.34-.53 1 1 0 0 0-.8.26v.27l-4.3 5.37c0 .27-.27.27-.54.54a1.68 1.68 0 0 0 .54 2.15 2 2 0 0 0 1.34.27c.27 0 .54-.27.81-.27l4.29-5.37c0-.27 0-.54.27-.54ZM37.57 48.24C37.3 48 37 48 36.49 48h-.27c-.27 0-.53.26-.8.26l-4.3 5.38-.27.26a1.62 1.62 0 0 0-.27 1.1 1.38 1.38 0 0 0 .54 1 1.18 1.18 0 0 0 1.34.27 2 2 0 0 0 1.08-.54.26.26 0 0 1 .27-.26l4.29-5.11a1.69 1.69 0 0 0-.53-2.12ZM42.67 52.54a1.38 1.38 0 0 0-.54-1.07 1.6 1.6 0 0 0-1.07-.27 1.4 1.4 0 0 0-1.08.54.26.26 0 0 1-.27.26l-4.29 5.38v.26a1 1 0 0 0-.27.81 1.37 1.37 0 0 0 .54 1.07 1.69 1.69 0 0 0 2.41-.26l4.3-5.38c0-.53.27-.8.27-1.34ZM42.67 56.3l-2.42 3 1.34 1.34a1.9 1.9 0 0 0 2.69-2.69Z'
        />
        <path
          className='cls-2'
          d='m50.19 38.58-3.49-3.76c-1.61 1.88-5.91 5.64-10.7 5.64A8.52 8.52 0 0 1 29 37l-.53-.8 9.93-9.94-1.1-1.11s-3.49-3.49-8.06-3.49a10.88 10.88 0 0 0-7.79 3.49 8.61 8.61 0 0 0-2.95 6.71c0 4.84 4 9.13 5.37 10.74L26 39.92a3.19 3.19 0 0 1 2.42-1.34 4.51 4.51 0 0 1 2.68.8 3.19 3.19 0 0 1 1.34 2.42v.27a3.79 3.79 0 0 1 2.42.8 3.19 3.19 0 0 1 1.34 2.42v.27a3.79 3.79 0 0 1 2.42.8A3.21 3.21 0 0 1 40 48.78a3 3 0 0 1 3 .81A3.2 3.2 0 0 1 44.28 52a4 4 0 0 1-.54 2.42l1.88 1.88a1.95 1.95 0 0 0 2.69 0 2 2 0 0 0 .54-1.3 2 2 0 0 0-.54-1.34l-.31-.31-6.71-7A.95.95 0 1 1 42.67 45a.26.26 0 0 1 .27.27l6.71 7a2.44 2.44 0 0 0 2.69 0 1.7 1.7 0 0 0 .27-2.41l-.27-.27-6.72-7a1.19 1.19 0 0 1 0-1.34A1.62 1.62 0 0 1 46.7 41c.27 0 .27 0 .53.27l6.72 7a.26.26 0 0 0 .27.27 1.71 1.71 0 0 0 2.41-.27 2 2 0 0 0 .54-1.34 2 2 0 0 0-.54-1.34Z'
        />
      </g>
    </g>
  </svg>
)

export default SvgComponent
