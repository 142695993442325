import * as React from 'react'

const SvgComponent = props => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 54 60' {...props}>
    <defs>
      <style>{'.cls-1{fill:#6b6cff}'}</style>
    </defs>
    <g id='\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          d='m39.08 18.52-10.76 9.87-7.34-4.64-.08-.1-.04.03-.06-.04-.11.16-10.4 7.24 1.31 1.67 9.32-6.49 7.57 4.77.07.07.02-.01.04.02.13-.18 11.86-10.87 2.31 2.29.86-6.59-6.92.59'
          style={{
            stroke: '#6b6cff',
            fill: '#6b6cff',
          }}
        />
        <path
          d='m20.2 30.91-9.98 6.9v6.47h10l-.02-13.37zM32.19 33.06l-3.22 3.06-5.75-3.74v11.9h9l-.03-11.22zM43.2 23.43l-8.98 8.21v12.64h9l-.02-20.85z'
          style={{
            stroke: '#6b6cff',
            fill: '#6b6cff',
          }}
        />
        <path
          d='M52.5 18.32a6.5 6.5 0 0 0-3-5.1L30 2.19a6.87 6.87 0 0 0-6 0l-19.49 11a6.42 6.42 0 0 0-3 5.14v23.3a6.5 6.5 0 0 0 3 5.11l19.5 11a6.61 6.61 0 0 0 6 0l19.5-11a6.5 6.5 0 0 0 3-5.11Z'
          style={{
            fill: 'none',
            stroke: '#363649',
            strokeMiterlimit: 10,
            strokeWidth: 3,
          }}
        />
      </g>
    </g>
  </svg>
)

export default SvgComponent
