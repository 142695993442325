import * as React from 'react'

const SvgComponent = props => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80' {...props}>
    <defs>
      <style>{'.cls-2{fill:#6b6cf9}'}</style>
    </defs>
    <g id='\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          id='a'
          d='M22.78 1.08a137.9 137.9 0 0 1 34.45 0 25 25 0 0 1 21.69 21.7 137.9 137.9 0 0 1 0 34.45 25 25 0 0 1-21.7 21.69A137.63 137.63 0 0 1 40 80a138.23 138.23 0 0 1-17.22-1.08 25 25 0 0 1-21.7-21.69 137.9 137.9 0 0 1 0-34.45 25 25 0 0 1 21.7-21.7Z'
          style={{
            fill: '#e9ebff',
          }}
        />
        <path
          className='cls-2'
          d='M46.2 43.6a.92.92 0 0 1-.15-1.6A11.14 11.14 0 1 0 34 42a.93.93 0 0 1-.15 1.64A16.3 16.3 0 0 0 24 55.83a2.31 2.31 0 0 0 .52 1.9 2.34 2.34 0 0 0 1.78.82h27.43A2.33 2.33 0 0 0 56 55.83a16.3 16.3 0 0 0-9.8-12.23Z'
        />
        <path
          className='cls-2'
          d='M64 50.48a10.8 10.8 0 0 0-6.52-8.1.59.59 0 0 1-.37-.51.58.58 0 0 1 .28-.57 7.37 7.37 0 0 0-3.61-13.56 5.66 5.66 0 0 0-.92 0 13.34 13.34 0 0 1 .89 4.55 13.68 13.68 0 0 1-4.15 10.12 18.9 18.9 0 0 1 8.16 9.86h4.69A1.54 1.54 0 0 0 64 50.48ZM30.39 42.42a13.77 13.77 0 0 1-3.24-14.68H27a7.37 7.37 0 0 0-4.4 13.56.61.61 0 0 1 .28.57.62.62 0 0 1-.38.51 10.8 10.8 0 0 0-6.5 8.1 1.56 1.56 0 0 0 1.53 1.8h4.69a18.85 18.85 0 0 1 8.17-9.86Z'
        />
      </g>
    </g>
  </svg>
)

export default SvgComponent
