import * as React from 'react'

const SvgComponent = props => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60' {...props}>
    <defs>
      <style>{'.cls-3{fill:#363649}'}</style>
    </defs>
    <g id='\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <g id='\u575A\u56FA\u7A33\u5B9A-48x48'>
          <circle
            id='Oval-7'
            cx={30}
            cy={30}
            r={28.5}
            style={{
              fill: 'none',
              stroke: '#363649',
              strokeMiterlimit: 10,
              strokeWidth: 3,
            }}
          />
          <path
            id='Rectangle-54'
            style={{
              fill: '#6b6cff',
            }}
            d='m30 33.72 14.87 7.43L30 48.59l-14.87-7.44L30 33.72z'
          />
          <g id='Group'>
            <path
              id='Rectangle-54-2'
              data-name='Rectangle-54'
              className='cls-3'
              d='m30 26.28 14.87 7.44L30 41.15l-14.87-7.43Zm0 2.77-9.33 4.67L30 38.38l9.33-4.66Z'
            />
            <path
              id='Combined-Shape'
              className='cls-3'
              d='M28.76 37.76v-20l-10 15Zm2.48-20v20l10-5Zm-16.11 16L30 11.41l14.87 22.31L30 41.15Z'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent
