import * as React from 'react'

const SvgComponent = props => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 50' {...props}>
    <defs>
      <style>
        {
          '.cls-1{fill:none;stroke:#363649;stroke-miterlimit:10;stroke-width:3px}.cls-2{fill:#6b6cff}'
        }
      </style>
    </defs>
    <g id='\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path id='Rectangle-34' className='cls-1' d='M1.5 1.5h57v47h-57Z' />
        <ellipse id='Oval-16' className='cls-2' cx={28.38} cy={28.19} rx={11.88} ry={11.79} />
        <path
          id='Combined-Shape'
          className='cls-1'
          d='M27.5 12.65V27.5H12.55A16.19 16.19 0 0 1 27.5 12.65Z'
        />
        <path
          id='Rectangle-35'
          style={{
            fill: '#5b597c',
          }}
          d='M44.5 11.5h6v3h-6z'
        />
        <path id='Rectangle-35-Copy' className='cls-2' d='M44.5 20.5h6v3h-6z' />
        <path id='Rectangle-35-Copy-2' className='cls-2' d='M44.5 28.5h6v3h-6z' />
        <path
          id='Rectangle-35-Copy-3'
          style={{
            fill: '#363649',
          }}
          d='M11 38h40v3H11z'
        />
      </g>
    </g>
  </svg>
)

export default SvgComponent
