import * as React from 'react'

const SvgComponent = props => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 50' {...props}>
    <defs>
      <style>{'.cls-1{fill:none;stroke:#363649;stroke-miterlimit:10;stroke-width:3px}'}</style>
    </defs>
    <g id='\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path id='Rectangle-23' className='cls-1' d='M1.5 1.5h57v38h-57z' />
        <path className='cls-1' d='M30.5 40v8M18 48.5h24' />
        <text
          transform='translate(10.18 28.14)'
          style={{
            fontSize: 18,
            fill: '#6b6cff',
            fontFamily: 'Roboto-Bold,Roboto',
            fontWeight: 700,
          }}
        >
          {'7x24'}
        </text>
      </g>
    </g>
  </svg>
)

export default SvgComponent
