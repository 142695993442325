import * as React from 'react'

const SvgComponent = props => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80' {...props}>
    <defs>
      <style>{'.cls-2{fill:#6b6cf9;fill-rule:evenodd}'}</style>
    </defs>
    <g id='\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          id='a'
          d='M22.78 1.08a137.9 137.9 0 0 1 34.45 0 25 25 0 0 1 21.69 21.7 137.9 137.9 0 0 1 0 34.45 25 25 0 0 1-21.7 21.69A137.63 137.63 0 0 1 40 80a138.23 138.23 0 0 1-17.22-1.08 25 25 0 0 1-21.7-21.69 137.9 137.9 0 0 1 0-34.45 25 25 0 0 1 21.7-21.7Z'
          style={{
            fill: '#e9ebff',
          }}
        />
        <path
          className='cls-2'
          d='M38.81 60.99 41 61l.04-6.99h-2.19l-.04 6.98zM50.25 27.82a13.13 13.13 0 0 0-25.53 4.26 13.62 13.62 0 0 0 .07 1.44A10.4 10.4 0 0 0 27.35 54h11.5l.06-9.87v-2.32l-2.35 2.32-2.32 2.3-1.54-1.55.77-.76 5.45-5.41h2.2l5.4 5.46.76.77-1.55 1.54-2.3-2.33-2.33-2.34v2.33L41 54h9.54a13.11 13.11 0 0 0-.33-26.2Z'
        />
      </g>
    </g>
  </svg>
)

export default SvgComponent
