import styled from 'styled-components'
import { Media, typography } from '@dayancloud/common/theme'
import Img from 'gatsby-image'

export const CooperateBannerContainer = styled.div`
  position: relative;
  height: 570px;
  z-index: 0;
  background: #ccc;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button {
    width: 200px;
    height: 50px;
    line-height: 50px;
  }
  ${Media.lessThan(Media.small)} {
    height: 300px;
    padding-top: 70px;
    button {
      width: 150px;
      height: 40px;
      line-height: 40px;
    }
  }
`
export const BannerLeft = styled.div`
  text-align: left;
  width: 1200px;
  color: #fff;
  flex-shrink: 0;
  padding-left: 42px;
  ${Media.lessThan(Media.small)} {
    width: 375px;
    text-align: center;
    padding-left: 0;
  }
`

export const CooperateBannerTitle = styled.h1`
  margin: 0;
  font-size: ${typography.h3};
  line-height: 1;
  font-weight: 400;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h4};
  }
`

export const CooperateBannerSubTitle = styled.p`
  font-size: ${typography.h1};
  font-weight: bold;
  margin: 26px 0;
  span {
    color: #6b6cf9;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h2};
    margin: 10px 0;
  }
`

export const CooperateProcess = styled.div`
  padding-top: 90px;
  background: #fff;
  ${Media.lessThan(Media.small)} {
    padding-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const CooperateTitle = styled.h2`
  text-align: center;
  font-size: ${typography.title};
  font-weight: 500;
  margin: 0;
  ${Media.lessThan(Media.small)} {
    font-size: 20px;
    font-weight: bold;
  }
`

export const CooperateProcessContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 82px 0 100px 0;
  svg {
    width: 80px;
  }
  div {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  ${Media.lessThan(Media.small)} {
    padding: 39px 46px 46px 46px;
    flex-wrap: wrap;
    position: relative;
    height: 400px;
    width: 385px;
    svg {
      width: 40px;
    }
    .dash02 {
      width: 1px;
      height: 90px;
      position: absolute;
      top: 150px;
      margin: 0;
      right: 22%;
      :before {
        left: -3px;
        top: -10px;
      }
      :after {
        right: -4px;
        top: 91px;
      }
    }
    .process03 {
      order: 3;
      margin-top: 120px;
    }
    .process04 {
      order: 1;
      margin-top: 120px;
    }
    .dash03 {
      order: 2;
      margin-top: 176px;
    }
  }
`

export const Dash = styled.div`
  width: 240px;
  height: 1px;
  border: 1px dashed #8796aa;
  margin: 120px 10px 0 10px;
  position: relative;
  ::before {
    position: absolute;
    content: '';
    width: 7px;
    height: 7px;
    border: 1px solid #8796aa;
    border-radius: 50%;
    left: -10px;
    top: -3px;
  }
  ::after {
    position: absolute;
    content: '';
    width: 7px;
    height: 7px;
    border: 1px solid #8796aa;
    border-radius: 50%;
    right: -10px;
    top: -3px;
  }
  ${Media.lessThan(Media.small)} {
    width: 120px;
    margin: 56px 13px 0 13px;
  }
`

export const Num = styled.span`
  color: #6b6cff;
  font-size: ${typography.h2};
  margin: 20px 0;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h4};
    margin: 5px 0;
  }
`

export const ProcessText = styled.span`
  font-size: 20px;
  ${Media.lessThan(Media.small)} {
    font-size: 16px;
  }
`

export const AgencyInterest = styled.div`
  padding-top: 90px;
  ${Media.lessThan(Media.small)} {
    padding-top: 45px;
  }
`

export const AgencyInerestContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 60px auto;
  justify-content: space-around;
  div {
    width: 280px;
    height: 200px;
    background: #ffffff;
    border-radius: 20px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
      box-shadow: 0px 10px 20px 0px rgba(107, 108, 249, 0.08);
    }
  }
  svg {
    width: 54px;
    height: 60px;
  }
  span {
    font-size: ${typography.h4};
    color: #000000;
    line-height: 24px;
    margin-top: 26px;
  }
  ${Media.lessThan(Media.small)} {
    margin: 24px auto;
    div {
      width: 170px;
      height: 100px;
      border-radius: 10px;
      margin-bottom: 15px;
    }
    svg {
      width: 27px;
      height: 30px;
    }
    span {
      font-size: 12px;
      margin-top: 10px;
      line-height: 12px;
    }
  }
`

export const AgencyScope = styled.div`
  background: #fff;
  padding-top: 90px;
  ${Media.lessThan(Media.small)} {
    padding-top: 36px;
  }
`

export const AgencyScopeContent = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 60px auto 0px;
  justify-content: space-around;
  padding-bottom: 107px;
  ${Media.lessThan(Media.small)} {
    margin: 40px auto 0px;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 40px;
  }
`

export const ScopeItem = styled.div`
  width: 280px;
  height: 400px;
  background: url(${p => p.backgroundImg});
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #fff;
  align-items: center;
  padding: 40px 30px;
  font-size: 20px;
  ${Media.lessThan(Media.small)} {
    width: 140px;
    height: 200px;
    background-size: 100%;
    margin: 7px;
    font-size: ${typography.textThin};
    padding: 25px 10px;
  }
`

export const AgencyRule = styled.div`
  background: #f4f7ff;
  padding-top: 90px;
  ${Media.lessThan(Media.small)} {
    padding-top: 30px;
  }
`

export const RuleContent = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 66px;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    align-items: center;
    padding-bottom: 36px;
    margin-top: 5px;
  }
`

export const RuleImg = styled(Img)`
  width: 445px;
  height: 400px;
  ${Media.lessThan(Media.small)} {
    width: 278px;
    height: 250px;
    margin-top: 20px;
  }
`

export const Text = styled.div`
  margin-left: 116px;
  h3 {
    color: #6b6cf9;
    font-size: ${typography.h3};
    font-weight: 500;
    margin-top: 50px;
  }
  p {
    width: 550px;
    height: 50px;
    color: #7d829c;
    font-size: ${typography.h4};
    line-height: 36px;
  }
  ${Media.lessThan(Media.small)} {
    margin: auto;
    h3 {
      font-size: ${typography.h4};
      margin-top: 22px;
    }
    p {
      width: 330px;
      height: 46px;
      font-size: ${typography.textThin};
      line-height: 18px;
    }
  }
`

export const JoinUs = styled.div`
  height: 280px;
  background: #6b6cf9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    font-size: 36px;
    font-weight: bold;
    color: #f2f2f6;
    text-align: center;
    margin: 0;
  }
  .join-btn {
    width: 202px;
    height: 52px;
    border: 2px solid #f2f2f6;
    border-radius: 6px;
    margin-top: 34px;
    &:hover {
      background: #fff;
      color: #6b6cf9;
    }
  }
  ${Media.lessThan(Media.small)} {
    p {
      font-size: 24px;
    }
    button {
      width: 151px;
      height: 41px;
      line-height: 41px;
      border: 1px solid #f2f2f6;
      border-radius: 5px;
      margin: 19px auto 42px;
      font-size: 18px;
    }
  }
`
