import * as React from 'react'

const SvgComponent = props => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60' {...props}>
    <defs>
      <style>
        {
          '.cls-01{fill:#6b6cff;stroke:#6b6cff;stroke-miterlimit:10;stroke-width:1}.cls-02{fill:#363649}'
        }
      </style>
    </defs>
    <g id='\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path id='Fill-2' className='cls-01' d='M21.76 43.76h22v-22h-22v22z' />
        <path
          id='Fill-3'
          className='cls-02'
          d='M33.9 16.24v3h3.6L19.24 39.5v-5.6h-3v9.86h9.86v-3h-4.6L40.76 19.6v4.5h3V16.24H33.9z'
        />
        <path id='Fill-4' className='cls-02' d='M40.76 40.76H33.9v3h9.86V33.9h-3v6.86z' />
        <path id='Fill-5' className='cls-01' d='M19.24 19.24h6.86v-3h-9.86v9.86h3v-6.86z' />
        <circle
          id='Oval-7'
          cx={30}
          cy={30}
          r={28.5}
          style={{
            fill: 'none',
            stroke: '#363649',
            strokeMiterlimit: 10,
            strokeWidth: 3,
          }}
        />
      </g>
    </g>
  </svg>
)

export default SvgComponent
