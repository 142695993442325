import * as React from 'react'

const SvgComponent = props => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 50' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <g data-name='\u56FE\u5C42 1'>
        <path
          d='M52.5 47.5v-5L60 50H0V6.25l2.5 2.5V47.5ZM2.5 0H60v43.75l-2.5-2.5V2.5h-50v5L0 0Z'
          style={{
            fill: '#363649',
          }}
        />
        <circle
          cx={22.5}
          cy={25}
          r={11.25}
          style={{
            fill: '#6b6cff',
          }}
        />
        <circle
          cx={37.5}
          cy={25}
          r={10}
          style={{
            fill: 'none',
            stroke: '#363649',
            strokeMiterlimit: 10,
            strokeWidth: 3,
          }}
        />
      </g>
    </g>
  </svg>
)

export default SvgComponent
