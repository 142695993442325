import React from 'react'
import { graphql } from 'gatsby'
import { Media } from '@dayancloud/common/theme'
import { Billboard, Button, Layout, SEO, Modal, AgencyForm } from '@dayancloud/common/components'
import {
  CooperateBannerContainer,
  BannerLeft,
  CooperateBannerSubTitle,
  CooperateBannerTitle,
  CooperateProcess,
  CooperateTitle,
  CooperateProcessContainer,
  Dash,
  Num,
  ProcessText,
  AgencyInterest,
  AgencyInerestContent,
  AgencyScope,
  AgencyScopeContent,
  ScopeItem,
  AgencyRule,
  RuleContent,
  Text,
  JoinUs,
  RuleImg,
} from './cooperate.atom'
import Process1 from '../images/cooperate/process1.jsx'
import Process2 from '../images/cooperate/process2.jsx'
import Process3 from '../images/cooperate/process3.jsx'
import Process4 from '../images/cooperate/process4.jsx'
import Agenct1 from '../images/cooperate/agencyInerest1.jsx'
import Agenct2 from '../images/cooperate/agencyInerest2.jsx'
import Agenct3 from '../images/cooperate/agencyInerest3.jsx'
import Agenct4 from '../images/cooperate/agencyInerest4.jsx'
import Agenct5 from '../images/cooperate/agencyInerest5.jsx'
import Agenct6 from '../images/cooperate/agencyInerest6.jsx'
import Agenct7 from '../images/cooperate/agencyInerest7.jsx'
import Agency8 from '../images/cooperate/agencyInerest8.jsx'
import Scope1 from '../images/cooperate/agencyScope1.png'
import Scope2 from '../images/cooperate/agencyScope2.png'
import Scope3 from '../images/cooperate/agencyScope3.png'
import Scope4 from '../images/cooperate/agencyScope4.png'

const scopeList = [
  {
    img: Scope1,
    title: '无人机行业',
  },
  {
    img: Scope2,
    title: '测绘与地理信息行业',
  },
  {
    img: Scope3,
    title: '物联网与人工智能行业',
  },
  {
    img: Scope4,
    title: '智慧产业',
  },
]

const AgencyInerestList = [
  {
    agent: <Agenct1 />,
    title: '高推广收益特权',
  },
  {
    agent: <Agenct2 />,
    title: '渠道专属管理系统',
  },
  {
    agent: <Agenct3 />,
    title: '本地合作启动会议支持',
  },
  {
    agent: <Agenct4 />,
    title: '生态合作推介会系列活动',
  },
  {
    agent: <Agenct5 />,
    title: '大雁云官网优先展示特权',
  },
  {
    agent: <Agenct6 />,
    title: '大雁云媒体渠道推介',
  },
  {
    agent: <Agenct7 />,
    title: '专属商务团队维护',
  },
  {
    agent: <Agency8 />,
    title: '7*24h在线客服答疑',
  },
]

class Cooperate extends React.PureComponent {
  state = {
    showDialog: false,
    showForm: false,
  }
  changeFormShow(e, isOpen) {
    if (e) e.preventDefault()
    this.setState({ showDialog: false })
    this.setState({ showForm: isOpen })
  }
  changeDialogOpen(e, isOpen) {
    if (e) e.preventDefault()
    this.setState({ showDialog: isOpen })
    this.setState({ showForm: false })
  }
  isSmallScreen = () => {
    return typeof window === 'undefined'
      ? false
      : window.innerWidth <= Media.sizeMap.get(Media.small).min
  }

  render() {
    const {
      data,
      location,
      pageContext: { topFiveNews },
    } = this.props
    const { showDialog, showForm } = this.state
    return (
      <Layout location={location} topFiveNews={topFiveNews}>
        <SEO
          title='大雁云生态合作，代理推广 - 大雁云三维实景建模专家'
          keywords='大雁云，三维实景建模，大雁云生态合作，大雁云合作伙伴，无人机拍摄建模，云端建模'
          description='大雁云三维实景建模是一款为用户提供高效、安全、流畅的无人机拍摄建模，云端建模平台，加入大雁云生态合作代理推广，可获得高达30%高额佣金，诚挚邀请您的加入。'
          ogUrl='https://www.dayancloud.com/cooperate.html'
          ogType='website'
        />
        {this.isSmallScreen() && showForm ? (
          <AgencyForm
            isOpen={() => {
              this.changeFormShow(null, false)
              this.changeDialogOpen(null, false)
            }}
          />
        ) : (
          <>
            <CooperateBannerContainer>
              <BannerLeft>
                <CooperateBannerTitle>加入大雁云代理推广</CooperateBannerTitle>
                <CooperateBannerSubTitle>
                  高达<span>30%</span>高额佣金
                </CooperateBannerSubTitle>
                <Button
                  id='btn-ljjr'
                  onClick={e => {
                    this.isSmallScreen()
                      ? this.changeFormShow(e, true)
                      : this.changeDialogOpen(e, true)
                  }}
                >
                  立即加入
                </Button>
              </BannerLeft>
              <Billboard fluid={data.banner.childImageSharp.fluid} />
            </CooperateBannerContainer>
            <CooperateProcess>
              <CooperateTitle>成为代理</CooperateTitle>
              <CooperateProcessContainer>
                <div>
                  <Process1 />
                  <Num>01</Num>
                  <ProcessText>申请合作</ProcessText>
                </div>
                <Dash />
                <div>
                  <Process2 />
                  <Num>02</Num>
                  <ProcessText>上传资料</ProcessText>
                </div>
                <Dash className='dash02' />
                <div className='process03'>
                  <Process3 />
                  <Num>03</Num>
                  <ProcessText>官网审核</ProcessText>
                </div>
                <Dash className='dash03' />
                <div className='process04'>
                  <Process4 />
                  <Num>04</Num>
                  <ProcessText>成为代理</ProcessText>
                </div>
              </CooperateProcessContainer>
            </CooperateProcess>
            <AgencyInterest>
              <CooperateTitle>代理权益</CooperateTitle>
              <AgencyInerestContent>
                {AgencyInerestList.map((item, index) => {
                  return (
                    <div key={index}>
                      {item.agent}
                      <span>{item.title}</span>
                    </div>
                  )
                })}
              </AgencyInerestContent>
            </AgencyInterest>
            <AgencyScope>
              <CooperateTitle>代理人范围</CooperateTitle>
              <AgencyScopeContent>
                {scopeList.map((item, index) => {
                  return (
                    <ScopeItem backgroundImg={item.img} key={index}>
                      <div className='title'>{item.title}</div>
                    </ScopeItem>
                  )
                })}
              </AgencyScopeContent>
            </AgencyScope>
            <AgencyRule>
              <CooperateTitle>代理规则</CooperateTitle>
              <RuleContent>
                <RuleImg fluid={data.ruleImg.childImageSharp.fluid} />
                <Text>
                  <h3>合作资格</h3>
                  <p>
                    ⑴ 有志于在无人机、测绘行业、云计算、智慧产业等方向发展的伙伴
                    <br />⑵ 对云计算有浓厚兴趣并希望向该方向发展的伙伴
                  </p>
                  <h3>合作形式</h3>
                  <p>
                    大雁云授权制，双方共同打造GIS行业双向赋能，聚力融合，联合推广的上下游生态合作圈。
                  </p>
                </Text>
              </RuleContent>
            </AgencyRule>
            <JoinUs>
              <div>
                <p>如果您对大雁云代理合作感兴趣</p>
                <p>立即加入成为合伙人吧！</p>
              </div>
              <Button
                id='btn-ljjr'
                className='join-btn'
                onClick={e => {
                  this.isSmallScreen()
                    ? this.changeFormShow(e, true)
                    : this.changeDialogOpen(e, true)
                }}
              >
                立即加入
              </Button>
            </JoinUs>
            <Modal
              simpleHeader
              isSimpleCloseStyle={true}
              bodyStyle={{ padding: '37px 70px 50px 50px' }}
              visible={showDialog}
              onClose={e => {
                this.changeDialogOpen(e)
              }}
            >
              <AgencyForm
                isOpen={() => {
                  this.changeFormShow(null, false)
                  this.changeDialogOpen(null, false)
                }}
              />
            </Modal>
          </>
        )}
      </Layout>
    )
  }
}

export default Cooperate

export const query = graphql`
  query {
    banner: file(relativePath: { regex: "/cooperate/bg.png/" }) {
      ...fluidImage
    }
    ruleImg: file(relativePath: { regex: "/cooperate/rule.png/" }) {
      ...fluidImage
    }
  }
`
