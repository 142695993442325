import * as React from 'react'

const SvgComponent = props => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 50' {...props}>
    <defs>
      <style>
        {
          '.cls-1{fill:none;stroke:#363649;stroke-miterlimit:10;stroke-width:3px}.cls-2{fill:#6b6cff}.cls-3{fill:#363649}'
        }
      </style>
    </defs>
    <g id='\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path id='Rectangle-23' className='cls-1' d='M1.5 1.5h57v47h-57z' />
        <path
          id='Rectangle-57'
          className='cls-2'
          d='m35 27.79 6 1.2 6-1.2v14.4l-6-3.6-6 3.6v-14.4z'
        />
        <circle id='Oval-21' className='cls-1' cx={40.96} cy={18.88} r={9.91} />
        <path id='Rectangle-58' className='cls-3' d='M10 14h12v3H10z' />
        <path id='Rectangle-58-Copy' className='cls-3' d='M10 24h12v3H10z' />
        <path id='Rectangle-58-Copy-2' className='cls-3' d='M10 34h12v3H10z' />
        <circle id='Oval-24' className='cls-2' cx={40.96} cy={18.88} r={4.96} />
      </g>
    </g>
  </svg>
)

export default SvgComponent
